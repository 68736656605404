<template>
  <div id="app" :class="{'phoneClass':$store.state.screenWidth<=768}" v-if="firstFlag">
    <Header v-if="$route.meta.pc" :path='this.$route.path'></Header>
    <!-- <Banner v-if="$route.meta.pc"></Banner> -->
    <router-view />
    <Footer v-if="$route.meta.pc"></Footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
      screenWidth:360,
      firstFlag:true,
    }
  },
  mounted() {
    //得到html的Dom元素
    let htmlDom = document.getElementsByTagName('html')[0];
    // if(htmlWidth>640){//超过640大小的，字体根部都是16px
    //   htmlWidth=640;
    //   console.log('htmlWidth-true',htmlWidth)
    // }
    //设置根元素字体大小
    
    this.screenWidth = window.innerWidth||document.body.clientWidth
    this.$store.state.screenWidth = window.innerWidth||document.body.clientWidth
    console.log(' this.screenWidth', this.screenWidth)
    // console.log('document.body.clientWidth',document.body.clientWidth,document.body.clientWidth<768,document.documentElement.clientWidth / 36)
    window.onresize = () => {
      return (() => {
        this.screenWidth = window.innerWidth||document.body.clientWidth
        this.$store.state.screenWidth = window.innerWidth||document.body.clientWidth
        console.log(' this.screenWidth',document.body.clientWidth,window.innerWidth)
      })()
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
/* font-family: PingFang SC; */
}
body {
user-select: none; /* 禁止选择 */
-moz-user-select: none; /* Firefox */
-webkit-user-select: none; /* Safari 和 Chrome */
-ms-user-select: none; /* Internet Explorer 和 Edge */
}
/* 媒体查询（手机） */
/* @media screen and (max-width: 768PX) {
  #app {
    min-width: 1280px;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.3);
    transform-origin: left top;
    transform: scale(calc(375 / 1280));
  }
} */
</style>
