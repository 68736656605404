<template>
  <div id="footer"
       class="container-fluid">
    <div class="item">
      <div class="email_wx">
        <p class="title">{{$t("footer.title1")}}</p>
        <p>cooperation@allsaintsmusic.com</p>
        <p>bd@allsaintsmusic.com</p>
      </div>
      <div class="address_tel_fax">
        <p class="title">{{$t("footer.title2")}}</p>
        <p>{{$t("footer.address1")}}</p>
        <p>{{$t("footer.address2")}}</p>
        <p>{{$t("footer.address3")}}</p>
        <!-- <p v-if="$i18n.locale == 'en'">{{$t("footer.address3")}}</p> -->
      </div>
      <div class="logo">
        <img src="@/assets/img/logo_black.png"
             alt="logo图">
      </div>
    </div>
    <p class="copy">© Copyright 2019 AllSaints Music Group.{{$t("footer.title3")}} <a @click="go()"
         style="cursor: pointer;">{{$t("footer.title4")}}</a></p>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data () {
    return {};
  },
  methods: {
    go () {
      window.open("https://beian.miit.gov.cn/")
    }
  }
};
</script>
<style scoped lang="less">
#footer {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  overflow: hidden;
  text-align: left;
}
#footer .item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40PX;
  box-sizing: border-box;
  max-width: 1280PX;
  margin: 0 auto;
}
.logo {
  width: 160PX;
  height: 80PX;
}
.logo img {
  width: 100%;
}
.address_tel_fax p,
.email_wx p {
  color: rgba(0, 0, 0, 0.55);
/* font-family: PingFang SC; */
  font-size: 14PX;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4PX;
}
.address_tel_fax .title,
.email_wx .title {
  color: rgba(0, 0, 0, 0.85);
/* font-family: PingFang SC; */
  font-size: 16PX;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12PX;
}
.address_tel_fax{
  padding:0 10px;
}
.copy {
  color: rgba(0, 0, 0, 0.3);
/* font-family: PingFang SC; */
  font-size: 14PX;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20PX 0;
  text-align: center;
  background: #f5f5f5;
}
.copy a {
  color: rgba(0, 0, 0, 0.3);
  text-decoration: none;
  pointer-events: painted;
}
@media screen and (max-width:768PX) {
  .address_tel_fax{
    padding: 0;
  }
  #footer .item{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    .email_wx{
      margin-bottom: 24px;
      padding: 8px 0;
      .title{
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
      }
      p{
        font-size: 12px;
        line-height: 17px;
      }
    }
    .logo{
      padding: 24px 0;
      height: 60px;
      margin: 24px auto;
      img{
        width: 120px;
        height: 60px;
      }

    }
  }
  .copy{
    padding: 0 10px 20px;
    font-size: 10px;
    line-height: 14px;
    
  }
}
</style>

