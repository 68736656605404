import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
import 'amfe-flexible'
/* 头部组件 */
import Header from './components/Header'
Vue.component(Header.name, Header)
/* 轮播组件 */
// import Banner from './components/Banner'
// Vue.component(Banner.name, Banner)


/* 尾部组件 */
import Footer from './components/Footer'
Vue.component(Footer.name, Footer)

const i18n = new VueI18n({
  locale: sessionStorage.getItem('language') || 'zh', // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    zh: require('./assets/languages/zh.json'),
    en: require('./assets/languages/en.json')
  }
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    // document.title = sessionStorage.getItem('language') == 'en' ? to.meta.title1 : to.meta.title
    document.title = sessionStorage.getItem('language') == 'en' ? 'AllSaints Music' : '万声文化'
  }
  next()
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
